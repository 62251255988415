import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { ERoles } from '../interfaces/roles.enum';

@Directive({
  selector: '[appHasRole]',
  standalone: true
})
export class HasRoleDirective {
  private currentUserRole: ERoles;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
    this.currentUserRole = this.authService.currentUser()?.rol as ERoles || ERoles.INVITADO;
      this.updateView();
  }

  @Input() set appHasRole(roles: ERoles[]) {
    this.updateView(roles);
  }

  private updateView(roles?: ERoles[]): void {
    this.viewContainer.clear();
    if (roles && roles.includes(this.currentUserRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}